<template>
  <div class="app-container">
    <!-- 筛选 -->
    <div class="content_select">
      <div style="display: flex;height: 25px;align-items: center;">
        <el-input placeholder="请输入线索名称/联系人/手机号" v-model="listQuery.infoFilter" style="width: 190px;margin-left:5px" size="small" @keyup.enter="getList" clearable/>
        <el-button
          type="primary"
          @click="getList"
          size="small"
          style="margin-right: 5px"
          icon="Search"
          >搜索</el-button
        >
        <search @success="getList" @cancel="cancel" name="高级筛选">
          <el-form
            style="width: 400px;"
            label-width="110px"
          >
            <el-form-item label="录入日期："> 
              <el-date-picker
                style="width:110px;"
                size="small"
                v-model="listQuery.createdAtBegin"
                type="date"
                placeholder="起始日期"
                clearable
                value-format="YYYY-MM-DD"
              /> &nbsp;-&nbsp;
              <el-date-picker
                style="width:110px;"
                size="small"
                v-model="listQuery.createdAtEnd"
                type="date"
                placeholder="终止日期"
                clearable
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
            <el-form-item label="分配日期："> 
              <el-date-picker
                style="width:110px;"
                size="small"
                v-model="listQuery.assignAtBegin"
                type="date"
                placeholder="起始日期"
                clearable
                value-format="YYYY-MM-DD"
              /> &nbsp;-&nbsp;
              <el-date-picker
                style="width:110px;"
                size="small"
                v-model="listQuery.assignAtEnd"
                type="date"
                placeholder="终止日期"
                clearable
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
            <el-form-item label="线索状态："> 
              <el-select
                v-model="listQuery.status"
                placeholder="请选择"
                style="width: 234px; margin: 0"
                filterable
                size="small"
              >
                <el-option
                  v-for="item in culeStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="跟进阶段："> 
              <select-clue
                v-model:clueStatus="listQuery.followStage"
                placeholder="请选择"
                :type="'follow'"
                style="width: 234px; margin: 0"
              />
              <!-- <el-select
                v-model="listQuery.status"
                placeholder="请选择"
                style="width: 234px; margin: 0"
                filterable
                size="small"
              >
                <el-option
                  v-for="item in culeStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item label="客户来源："> 
              <el-select
                v-model="listQuery.customerSource"
                placeholder="请选择"
                style="width: 234px; margin: 0"
                filterable
                size="small"
              >
                <el-option
                  v-for="item in customerStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所在地区："> 
              <addressList v-model:type="listQuery.customerDistrict" style="width:234px;margin: 0;"></addressList>
            </el-form-item>
            <el-form-item label="跟进负责人："> 
              <selectuser code="addCustomer" v-model:userId="listQuery.followUserId" :multiples="false" style="width: 234px;margin: 0;" ></selectuser>
            </el-form-item>
          </el-form>
        </search>
        <span
          style="margin-left: 15px"
          class="textColor"
          @click="selectClueType('全部')"
          :class="{ selected: selectedItem === '全部' }" 
          >全部</span
        >
        <span
          class="textColor"
          @click="selectClueType('未分配')"
          :class="{ selected: selectedItem === '未分配' }" 
          >未分配</span
        >
        <span
          class="textColor"
          @click="selectClueType('已分配')"
          :class="{ selected: selectedItem === '已分配' }" 
          >已分配</span
        >
      </div>
      <div>
        <el-dropdown style="margin:0 5px">
          <el-button type="primary" size="small" icon="CirclePlus" @click="addClue">
            新增<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="toDaoRu">导入</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-button
          type="success"
          @click="toAllocation"
          size="small"
          icon="Promotion"
          >分配</el-button
        >
        <el-button
          type="warning"
          @click="toReceive"
          size="small"
          icon="Finished"
          >领取</el-button
        >
        <el-button
          type="danger"
          @click="toDel"
          size="small"
          icon="Delete"
          >删除至回收站</el-button
        >
        <colSetting
          name="线索池"
          btnName="显示列"
          @arrangeInfo="getInfo"
          @load="infoLoading"
        />
      </div>
    </div>
    <!-- 列表 -->
    <div class="table_container">
      <el-table
        :data="clueList"
        style="width: 100%; margin-top: 10px"
        border
        :height="contentStyleObj"
        @selection-change="handleSelectClue"
        v-loading="listLoading"
        stripe
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" width="55" align="center" fixed />
        <el-table-column
          prop="customerName"
          label="客户名称"
          fixed
          min-width="200"
          align="left"
        >
          <template #default="scope">
            <span class="cosName" @click="toEdit(scope.row)">{{ scope.row.customerName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="customerContacter"
          label="客户联系人"
          min-width="100"
          align="left"
        />
        <el-table-column
          prop="customerContacterMobile"
          label="联系人手机"
          min-width="110"
          align="left"
        />
        <el-table-column
          prop="level"
          label="线索等级"
          min-width="80"
          align="left"
        />
        <el-table-column
          prop="status"
          label="线索状态"
          min-width="80"
          align="left"
        >
          <template #default="scope">
            {{ (culeStatusList.find(item => item.value == scope.row.status)).label }}
          </template>
        </el-table-column>
        <el-table-column
          prop="followUserName"
          label="跟进负责人"
          min-width="100"
          align="left"
        />
        <el-table-column
          :prop="(arrangePropInfo.find(item => item.lable == val)).value"
          min-width="120"
          align="left"
          v-for="(val,i) in arrangeInfo"
        >
          <template #header>
            <span>{{val}}</span>
          </template>
          <template #default="scope" v-if="val == '客户来源'">
            <span v-if="scope.row.followStage != '0'">
              {{ (customerStatusList.find(item => item.value == scope.row.customerSource)).label }}
            </span>
            <span v-else>

            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createdTime"
          label="录入时间"
          min-width="120"
          sortable
          align="left"
        />
        <!-- <el-table-column
          label="操作"
          align="center"
          min-width="180"
          fixed="right"
        >
          <template #default="scope">
            
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="page_container">
      <qzf-pagination
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>
  </div>
  <!-- 跟进分配弹窗 -->
  <allocation ref="allocation" @success="getList"></allocation>
  <!-- 新增弹窗 -->
  <addfrom ref="addfrom" @success="getList"></addfrom>
  <!-- 编辑弹窗 -->
  <editfrom ref="editfrom" @success="getList"></editfrom>
  <!-- 导入 -->
  <daoru ref="daoru" @success="getList"></daoru>
</template>

<script>
import { CULE_STATUS,FOLLOW_STATUS,CUSTOMER_STATUS } from "@/utils/commonData";
import addfrom from "./components/addForm.vue"
import editfrom from "./components/editFrom.vue"
import allocation from "./components/allocation.vue"
import daoru from "./components/daoRu.vue"
import addressList from "@/components/Screening/address";
import { poolList,getCollect,getRecycle } from "@/api/newCrm.js";

export default {
  name: '',
  components: {
    addfrom,
    allocation,
    editfrom,
    daoru,
    addressList,
  },
  props: {},
  data () {
    return {
      // 查询参数
      listQuery: {
        page: 1,
        limit: 10,
        infoFilter: '',
        followUserId: null,//跟进负责人
        assignStatus: 1,//分配状态
        createdAtBegin: '',
        createdAtEnd: '',
        assignAtBegin: '',
        assignAtEnd: '',
        followStage: '',
        customerDistrict: '',
        customerSource: null,
      },
      total:0, // 列表数据总数
      listLoading: false,//列表loading
      infoLoading: false,
      clueList: [
        // {
        //   'contactsName':'林青玉',
        //   'contacter':'132455',
        //   'id':1
        // },
        // {
        //   'contactsName':'林青玉1',
        //   'contacter':'132455',
        //   'id':2
        // },
        // {
        //   'contactsName':'林青玉3',
        //   'contacter':'132455',
        //   'id':3
        // },
      ],//列表数据
      culeStatusList: CULE_STATUS,//线索状态
      followStatusList: FOLLOW_STATUS,//跟进状态
      customerStatusList: CUSTOMER_STATUS,//客户来源
      arrangeInfo: [],//显示列数据
      arrangePropInfo: [//列表列对应的prop数据
        { lable: "跟进阶段", value: "followStage" },
        { lable: "最近跟进时间", value: "lastFollowTime" },
        { lable: "最近跟进记录", value:"lastFollowContent" },
        { lable: "下次跟进时间", value:"" },
        { lable: "所在地区", value:"customerDistrict" },
        { lable: "办公地址", value:"customerAddress" },
        { lable: "成立日期", value:"incorporationTime" },
        { lable: "客户来源", value:"customerSource" },
        { lable: "备注", value:"remark" },
        { lable: "录入人", value:"inputClerkName" },
        { lable: "最近分配时间", value:"assignTime" },
        { lable: "上次释放时间", value:"" },
        { lable: "即将释放日期", value:"" },
      ],
      selectedItem: '未分配',//线索分配类型：0全部 1未分配 2已分配
      ids: [],//选择的数据
    }
  },
  computed: {},
  watch: {},
  created () {
    this.contentStyleObj = this.$getHeight(318);
    this.getList()
  },
  mounted () {},
  methods: {
    // 获取列表数据
    getList(){
      this.listLoading = true
      poolList(this.listQuery).then(res => {
        console.log(res);
        if(res.data.msg == 'success'){
          this.clueList = res.data.data.data
          this.total = res.data.data.total;
        }
        this.listLoading = false
      })
    },
    // 线索类型操作展示列表数据
    selectClueType(item) {  
      this.selectedItem = item; // 更新选中的项  
      if(item == '全部'){
        this.listQuery.assignStatus = 0
      }else if(item == '未分配'){
        this.listQuery.assignStatus = 1
      }else{
        this.listQuery.assignStatus = 2
      }
      this.getList(); 
    }, 

    // 列表勾选
    handleSelectClue(val) {
      this.ids = []
      let list = val;
      list.map((v,i)=>{
        this.ids.push(v.id)
      })
    },
    
    // 显示列操作
    getInfo(e) {
      console.log(e);
      this.arrangeInfo = e;
    },

    infoLoading(){
      this.listLoading = true
    },

    // 新增线索
    addClue(){
      this.$refs.addfrom.init()
    },

    // 分配
    toAllocation(){
      if(this.ids.length == 0){
        this.$message.warning('请至少选择一条线索分配');
        return
      }
      this.$refs.allocation.init(this.ids)
    },

    // 领取
    toReceive(){
      if(this.ids.length == 0){
        this.$message.warning('请至少选择一条线索领取');
        return
      }
      let message = '已选择'+ this.ids.length +'家客户，确认领取？';
      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      }).then(() => {
        getCollect({clueIds:this.ids}).then(res => {
          console.log(res);
          if (res.data.msg == 'success') {
            this.$qzfMessage("领取成功")
            this.getList()
          }
          // this.options = res.data.data.list
        })
      })
    },

    // 删除至回收站
    toDel(){
      if(this.ids.length == 0){
        this.$message.warning('请至少选择一条线索删除');
        return
      }
      this.$confirm('确定要将客户删除至回收站?确定后线索池、我的线索将不再查询到,可在回收站对数据进行恢复。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getRecycle({clueIds:this.ids}).then(res => {
          if (res.data.msg == 'success') {
            this.$qzfMessage("删除成功")
            this.getList()
          }
        })
      })
    },

    // 点击客户名称编辑
    toEdit(row){
      this.$refs.editfrom.init(row)
    },

    // 点击导入
    toDaoRu(){
      console.log('222');
      this.$refs.daoru.init()
    },

    //重置
    cancel(){
      this.listQuery = {
        page:1,
        limit:10,
        name:'',
      }
      if(this.selectedItem == '全部'){
        this.listQuery.assignStatus = 0
      }else if(this.selectedItem == '未分配'){
        this.listQuery.assignStatus = 1
      }else{
        this.listQuery.assignStatus = 2
      }
      this.getList()
    }
  }
}
</script>

<style scoped lang="less">
.content_select{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.textColor{
  margin: 0 6px;
  cursor: pointer;
  font-size: 14px;
}
.selected {  
  color: #17A2B8;  
} 
.drawerHead{
  display: flex;
  justify-content: space-between;
}
.cosName{
  cursor: pointer;
}
.cosName:hover{
  color: #17A2B8;  
}
.page_container{
  float: right;
  margin-top: 10px;
}
</style>
